<template>
  <v-data-table
    locale="fr-FR"
    :headers="filteredHeader"
    :items="items"
    @click:row="clicked"
    item-key="id"
    class="elevation-1"
    :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
    id="assetsTable"
    :items-per-page="1000"
    hide-default-footer
    :key="assetsKey"
  >
    <template v-slot:item="{ item, isMobile }">
      <mobile-row-asset
        :item="item"
        :headers="mobHeader"
        v-if="isMobile"
        rtl
        ignore-null-val
        :class="{ 'asset-level': item.level === 4 || item.level === 3 }"
        @expand="expandRow"
        :is-expanded="shoulExpand(item.id)"
        @n-expand="removeFromExpanded"
        @row-clicked="clicked"
      >
        <template v-slot:eventsnoresa="{ item }">
          <div
            class="incident description"
            v-if="item.eventsnoresa"
            v-html="item.eventsnoresa"
          ></div>
        </template>
      </mobile-row-asset>
      <c-row
        v-else
        :item="item"
        :headers="filteredHeader"
        rtl
        :class="{ 'asset-level': item.level === 4 || item.level === 3 }"
        ignore-null-val
        @click="clicked(item)"
      >
        <template v-slot:tickets="{ item }">
          {{ item.tickets === 0 ? "-" : item.tickets }}
        </template>
        <template v-slot:technique="{ item }">
          {{ item.technique === 0 ? "-" : item.technique }}
        </template>
        <template v-slot:eventsnoresa="{ item }">
          <div
            class="incident description"
            v-if="item.eventsnoresa"
            v-html="item.eventsnoresa"
          ></div>
        </template>
      </c-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { remove } from "../utils";
import MobileRowAsset from "../components/MobileRowAsset";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      expanded: []
    };
  },
  components: { MobileRowAsset },
  computed: {
    ...mapGetters(["getUserProfiles", "getTicketsTasks", "assetsKey"]),
    filteredHeader() {
      let headers = [
        {
          text: this.$t("header.name"),
          align: "center",
          sortable: false,
          value: "label",
          class: "primary white--text"
        },
        {
          text: this.$t("subHeader.processing_incidents"),
          align: "end",
          sortable: false,
          value: "tickets",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.resolved_incidents"),
          align: "end",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: "PERMANENTES",
          align: "right",
          sortable: false,
          value: "permanent",
          class: "border-l-0 primary white--text"
        },
        {
          text: this.$t("subHeader.event"),
          align: "left",
          sortable: false,
          value: "eventsnoresa",
          class: "primary white--text"
        }
      ];

      return headers;
    },
    mobHeader() {
      let mobheaders = [
        {
          text: this.$t("header.name"),
          align: "center",
          sortable: false,
          value: "label",
          class: "primary white--text"
        },
        {
          text: this.$t("subHeader.processing_incidents"),
          align: "center",
          sortable: false,
          value: "tickets",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.resolved_incidents"),
          align: "center",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.event"),
          align: "left",
          sortable: false,
          value: "eventsnoresa",
          class: "primary white--text"
        }
      ];

      return mobheaders;
    },
    filteredItems() {
      return this.items;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    clicked: function(row) {
      if (!row.name) {
        return;
      } else if (row.level === 5) {
        this.$router.push({ name: "lots.show", params: { id: row.id } });
      } else if (row.level === 4) {
        this.$router.push({ name: "assets.show", params: { id: row.id } });
      } else {
        this.$router.push({
          name: "assets.index",
          params: { assetID: row.id }
        });
      }
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    }
  }
};
</script>
