var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[[(_vm.isCartoMap)?_c('v-row',{staticClass:"mb-12",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"10","offset-md":"1"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('map-view',{attrs:{"list":_vm.locationListObjets}})],1)],1)],1):_vm._e()],(_vm.isMobile)?[_c('v-card',{attrs:{"width":"100%"}},[_c('v-carousel',{attrs:{"cycle":"","height":"330","hide-delimiter-background":"","hide-delimiters":"","show-arrows-on-hover":""}},_vm._l((_vm.documentsImages),function(slide){return _c('v-carousel-item',{key:slide.id,attrs:{"src":slide.image}})}),1)],1)]:[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"10","offset-md":"1"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-carousel',{attrs:{"cycle":"","height":"330","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.documentsImages),function(slide){return _c('v-carousel-item',{key:slide.id,attrs:{"src":slide.image}})}),1)],1)],1)],1)],(_vm.isMobile)?[_c('v-row',{staticClass:"mx-n4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("tableTitle.accommodation_list"))+" ")]),_c('asset-table-light',{attrs:{"items":_vm.currentLots}})],1)],1)],1)]:[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("tableTitle.accommodation_list"))+" ")]),_c('asset-table-light',{attrs:{"items":_vm.currentLots}})],1)],1)],1)],_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('toggle-button',{staticClass:"mt-n1",attrs:{"font-size":16,"value":_vm.sliderInit,"width":200,"height":35,"color":{
        checked: 'var(--v-primary-base)',
        unchecked: 'var(--v-primary-base)'
      },"labels":{
        checked: 'Toutes les données',
        unchecked: 'chargement rapide'
      }},on:{"change":function($event){return _vm.dataRange()}}}),_c('v-tooltip',{attrs:{"open-delay":"500","top":"","color":"#333F48"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"elevation-0 mt-n1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-information ")])]}}])},[_c('span',{staticClass:"pa-6"},[_vm._v("Le chargement rapide n'affiche que les 1000 derniers éléments")])])],1),_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-card-title',[_c('label',{staticClass:"switch mr-4"},[_c('input',{attrs:{"type":"checkbox"},on:{"click":_vm.toggleCheckbox}}),_c('div',{staticClass:"slider round"})]),_vm._v(" Mode avancé")])],1),(this.checkbox)?[_c('v-card',{staticClass:"pa-4 md-0 elevation-3",attrs:{"width":"100%","color":"grey"}},[_c('v-row',[_c('base-user-table',{key:_vm.refreshKey,attrs:{"items":_vm.staffList,"label":"Liste du STAFF","loading":_vm.loading}})],1),(_vm.isKitAdmin)?_c('v-card',{staticClass:"mt-2"},[_c('v-dialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-currency-eur")])],1)],1),_c('v-col',{staticClass:"ml-n12 mr-12",attrs:{"cols":"2"}},[_vm._v(" Configurer les services facturables ")])],1)]}}],null,false,3621543358),model:{value:(_vm.confDialog),callback:function ($$v) {_vm.confDialog=$$v},expression:"confDialog"}},[_c('v-card',[_c('v-row',[_c('service-editor',{attrs:{"comment":_vm.note_service,"title":"Services"}})],1)],1)],1)],1):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }