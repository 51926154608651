<template>
  <v-card>
    <template v-if="show">
      <template v-if="isLgts"
        ><lgts-assets-details> </lgts-assets-details
      ></template>
      <template v-else-if="isLocs"
        ><location-assets-details> </location-assets-details
      ></template>
      <!--<template v-else-if="isEsrf"><esrf-assets> </esrf-assets></template>
      <template v-else-if="is2alpes"
        ><deuxalpes-assets> </deuxalpes-assets
      ></template>
      <template v-else-if="isElts"
        ><elts-assets-details> </elts-assets-details
      ></template>-->
      <template v-else-if="isBats"
        ><batiment-assets-details> </batiment-assets-details
      ></template>
      <template v-else>
        <assets-details-default> </assets-details-default>
      </template>
    </template>
    <template v-else>
      <map-radius :list="whereList"></map-radius>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import assetsDetailsDefault from "./AssetDetailsDefault";
import LgtsAssetsDetails from "../clients/LgtsAssetDetails";
import LocationAssetsDetails from "../clients/LocationAssetDetails";
import MapRadius from "../components/MapRadius";
import { PORTAL_CONFIG } from "../config";
import BatimentAssetsDetails from "../clients/BatimentAssetDetails";
/*import EsrfAssets from "../clients/EsrfAsset";
import EltsAssetsDetails from "../clients/EltsAssetDetails";
import DeuxalpesAssets from "../clients/DeuxalpesAsset";*/

export default {
  name: "assets",
  components: {
    assetsDetailsDefault,
    LgtsAssetsDetails,
    /*EsrfAssets,
    EltsAssetsDetails,
    DeuxalpesAssets,*/
    BatimentAssetsDetails,
    LocationAssetsDetails,
    MapRadius
  },
  data() {
    return {
      whereList: []
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["isAdminUser", "getUserId", "getUserProfiles"]),

    isLocs() {
      return this.getUserProfiles.includes(36); //profil 36 kit_location
    },
    isLgts() {
      return this.getUserProfiles.includes(32); //profil 32 kit_logement
    },
    /*is2alpes() {
      return (
        this.$route.params.assetID === 102 ||
        this.$route.params.assetID === 105 ||
        this.getUserId === 68 ||
        this.getUserId === 69 ||
        this.getUserId === 70
      );
    },
    isEsrf() {
      return this.$route.params.assetID === 79;
    },*/
    isBats() {
      return this.getUserProfiles.includes(35); //profil 35 kit_batiment
    },
    show() {
      return !this.isAdminUser || this.$route.params.assetID;
    }
  },
  methods: {
    loadData() {
      if (this.getUserProfiles.includes(17)) {
        fetch(`${PORTAL_CONFIG.SERVER_WEB}/dev/where.php`)
          .then(response => {
            return response.json();
          })
          .then(result => {
            this.whereList = result;
          });
      }
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  }
};
</script>
