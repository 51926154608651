<template>
  <v-container>
    <template v-if="isMobile">
      <v-card flat width="98%" class="mb-6">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card width="100%">
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="slide in images"
            :key="slide.id"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="5">
          <v-card class="ml-12" outlined tile>
            <map-view :list="locationList"></map-view>
          </v-card>
        </v-col>
        <v-col md="6" offset-md="1">
          <v-card class="mr-12" outlined tile>
            <v-carousel
              cycle
              height="330"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="slide in images"
                :key="slide.id"
                :src="slide.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="isMobile">
      <v-row no-gutters class="mx-n4">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Liste des équipements
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
          <v-card class="mt-6">
            <v-card-title class="headline display-1">
              {{ $t("tableTitle.list_lots") }}
            </v-card-title>
            <asset-table :items="currentLots"></asset-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row align="center" justify="center" class="mt-4">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Liste des équipements
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapView from "../components/Map";
import AssetTable from "../components/AssetTable";
import AssetTableLight from "../clients/AssetTableLight";
import moment from "../plugins/moment";
import USER_API from "../api/user";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "BatimentAssetDetails",
  components: {
    MapView,
    AssetTable,
    AssetTableLight
  },
  data() {
    return {
      activeEntity: null,
      locationListObjets: []
    };
  },
  //mixins: [AssetsData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getTreeData",
      "getDocumentsImages",
      "getUserProfiles",
      "getAssets",
      "getUserToken",
      "getUserId"
    ]),
    currentAsset() {
      const assetId = this.$route.params.id;
      const asset = this.getAssets.find(asset => {
        return +asset.id === +assetId;
      });

      return asset ? asset : null;
    },
    currentLots() {
      const lots = this.currentAsset.children;
      const keys = lots.map(el => el.id);
      return this.getAssets.filter(asset => {
        return keys.includes(asset.id);
      });
    },
    images() {
      const assetId = this.$route.params.id;
      const assetImages = this.getDocumentsImages.filter(
        el => el.entities_id === +assetId
      );
      return assetImages;
    },
    locationList() {
      const size = this.currentLots.length;
      let isGps = 0;
      for (let i = 0; i < size; i++) {
        if (this.currentLots[i].latitude && this.currentLots[i].longitude) {
          isGps = 1;
        }
      }
      if (isGps === 1) {
        return this.currentLots
          .filter(el => el.level === 5)
          .map(el => {
            return {
              name: el.name,
              lat: el.latitude,
              lng: el.longitude,
              link: { name: "lots.show", params: { id: el.id } }
            };
          });
      } else {
        return [
          {
            name: this.currentAsset.name,
            lat: this.currentAsset.latitude,
            lng: this.currentAsset.longitude
          }
        ];
      }
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    moment,
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id == entity.entities_id);
      return parent ? parent.name : "";
    },
    loadData() {
      if (!this.display) {
        this.loading = true;
        USER_API.getEquipment("Logement")
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            this.locationListObjets = [];
            let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              for (let i = 0; i < size; i++) {
                if (this.list[i].gps && this.list[i].gps !== "/") {
                  nbgps = nbgps + 1;
                  this.locationListObjets.push({
                    name:
                      isNaN(this.list[i].name) === true
                        ? this.list[i].name.toUpperCase()
                        : this.list[i].name,
                    operationnel: this.list[i].operationnel,
                    /* si souci avec les markers, il faudrait peut etre utiliser cela
                    operationnel:
                      this.list[i].operationnel !== undefined
                        ? this.list[i].operationnel
                        : "NA",*/
                    lat: this.list[i].gps.split("/")[0],
                    lng: this.list[i].gps.split("/")[1],
                    link:
                      PORTAL_CONFIG.SERVER_WEB +
                      "/" +
                      PORTAL_CONFIG.APP_NAME +
                      "/" +
                      PORTAL_CONFIG.CLIENT_NAME +
                      "/ticket_it.php?user=" +
                      this.getUserToken +
                      "&code_pi=" +
                      this.list[i].code_pi +
                      "&entity=" +
                      this.$route.params.id +
                      "&lot=info_lgt"
                  });
                }
              }
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                if (this.list[i].operationnel === 1) {
                  this.list[i].operationnel = "OK";
                } else {
                  this.list[i].operationnel = "NOK";
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
                this.list[i].dimension = this.list[i].dimension;
                /*if (this.list[i].expirationdate != null) {
                  this.list[i].duree =
                    this.list[i].expirationdate.split("-")[0] -
                    moment().format("YYYY") +
                    " an(s)";
                }*/
              }
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
