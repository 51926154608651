<template>
  <v-col lg="12" class="px-md-3">
    <v-card-title v-if="isKitAdmin" class="headline display-1">
      {{ label }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('tableStatus.searchAll')"
        single-line
        hide-details
        class="mt-n2"
      >
      </v-text-field>
    </v-card-title>
    <v-card-subtitle v-if="!this.searched">
      {{
        listItems.length > 1
          ? listItems.length + " utilisateurs "
          : listItems.length + " utilisateur "
      }}
      /
      {{
        selectedUsers.length > 1
          ? selectedUsers.length + " utilisateurs sélectionnés"
          : selectedUsers.length + " utilisateur sélectionné"
      }}
    </v-card-subtitle>
    <v-card-subtitle v-else>
      {{
        selectedUsers.length > 1
          ? selectedUsers.length + " utilisateurs sélectionnés"
          : selectedUsers.length + " utilisateur sélectionné"
      }}
    </v-card-subtitle>
    <v-card-text v-if="isKitAdmin" class="px-2 px-md-4 mb-6">
      <v-data-table
        v-model="selectedUsers"
        :search="searched"
        :headers="headers"
        :items="listItems"
        item-key="id"
        :loading="loading"
        show-select
        class="elevation-1 table is_bordered"
        sort-by="date"
        sort-desc
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:header.data-table-select="{ props, on }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-avatar class="white ml-1" size="32">
                  <v-icon color="primary">menu</v-icon>
                </v-avatar>
              </v-btn>
            </template>
            <v-list class="pa-0" light>
              <v-list-item
                style="background-color: rgb(240, 240, 240);"
                @click="unselectAll()"
                :disabled="selectedUsers.length === 0"
              >
                Effacer sélection
              </v-list-item>
              <v-list-item
                @click="modUsers(selectedUsers)"
                :disabled="selectedUsers.length != 1"
              >
                Modifier le mail
              </v-list-item>
              <v-list-item
                style="background-color: rgb(240, 240, 240);"
                @click="changeNotif(selectedUsers)"
                :disabled="selectedUsers.length === 0"
              >
                +/- notification des incidents
              </v-list-item>
              <v-list-item
                @click="changeEdl(selectedUsers)"
                :disabled="selectedUsers.length === 0"
              >
                +/- notfication des états des lieux
              </v-list-item>
              <v-list-item
                style="background-color: rgb(240, 240, 240);"
                @click="changeMdp(selectedUsers)"
                :disabled="selectedUsers.length != 1"
              >
                Modifier le mot de passe
              </v-list-item>
              <v-list-item
                @click="delUsers(selectedUsers)"
                :disabled="selectedUsers.length === 0"
              >
                Supprimer
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.date_creation="{ item }">
          {{ formatDate(item.date_creation) }}
        </template>
        <template v-slot:item.password_last_update="{ item }">
          {{
            item.password_last_update
              ? formatDate(item.password_last_update)
              : "jamais"
          }}
        </template>
        <template v-slot:item.last_login="{ item }">
          {{ item.last_login ? formatDateH(item.last_login) : "jamais" }}
        </template>
        <template v-slot:item.email="{ item }">
          {{ item.email ? item.email : "-" }}
        </template>
        <template v-slot:item.isAdmin="{ item }">
          <v-icon color="black" v-if="item.isAdmin > 0">
            mdi-plus
          </v-icon>
          <v-icon small v-else>
            mdi-minus
          </v-icon>
        </template>
        <template v-slot:item.isNotif="{ item }">
          <v-icon color="black" v-if="item.isNotif > 0">
            mdi-plus
          </v-icon>
          <v-icon small v-else>
            mdi-minus
          </v-icon>
        </template>
        <template v-slot:item.isEdl="{ item }">
          <v-icon color="black" v-if="item.isEdl > 0">
            mdi-plus
          </v-icon>
          <v-icon small v-else>
            mdi-minus
          </v-icon>
        </template>
        <template v-slot:item.isPass="{ item }">
          <v-icon color="black" v-if="item.isPass > 0">
            mdi-plus
          </v-icon>
          <v-icon small v-else>
            mdi-minus
          </v-icon>
        </template>
      </v-data-table>
      <v-row no-gutters class="pa-2">
        <v-col cols="9">
          <div class="text-center pt-2 mr-n12">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-right mt-4">
            * -> toutes agences
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-row no-gutters class="pa-2">
        <v-col cols="1">
          <v-btn
            fab
            dark
            small
            color="primary"
            :href="autoCookieLink"
            target="_blank"
          >
            <v-icon dark>mdi-desktop-classic</v-icon></v-btn
          >
        </v-col>
        <v-col cols="2" class="ml-n12 mr-12">
          Déposer un cookie sur cet ordinateur
        </v-col>
        <v-col cols="1">
          <v-btn
            fab
            dark
            small
            color="primary"
            :href="mailLink"
            target="_blank"
          >
            <v-icon dark>mdi-cookie</v-icon></v-btn
          >
        </v-col>
        <v-col cols="2" class="ml-n12 mr-12">
          Envoyer le cookie staff par mail
        </v-col>
        <v-col v-if="isKitAdmin" cols="1">
          <v-dialog width="50%" v-model="confDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="primary" dark v-bind="attrs" v-on="on">
                <v-icon dark>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-card align="center">
              <v-card-title>
                <span class="headline">Modifier ma configuration</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="fromMail"
                        label="Nom Agence : "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="tel"
                        label="Téléphone Agence : "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-switch
                        v-model="bqo"
                        label="Mail de bilan quotidien"
                        color="primary"
                        false-value="0"
                        true-value="1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="rco"
                        label="Rappel de départ"
                        color="primary"
                        false-value="0"
                        true-value="1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="co"
                        label="Cloture automatique"
                        :items="heuresCloture"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-switch
                        v-model="onClose"
                        label="Statut R2R après cloture"
                        color="primary"
                        false-value="OUT"
                        true-value="R2R"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4" v-if="!smsRci || smsRci == 0" class="pt-6">
                      NB: Les rappels par SMS sont désactivés, merci de
                      contacter KIT pour les activer.
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="prestaR2R"
                        label="R2R pour prestataires"
                        color="primary"
                        false-value="0"
                        true-value="1"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="ifg"
                        label="Adresse de redirection du QR code en bas à gauche : "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="resetConfig">
                  Reset
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeConfig">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="changeConf">
                  MODIFIER
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-if="isKitAdmin" cols="2" class="ml-n12 mr-12">
          Configuration
          {{
            this.getEntity.filter(el => el.id == this.$route.params.id)[0].name
          }}
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../config";
import USER_API from "../api/user";
import { adminKitProfile } from "../utils";
import Swal from "sweetalert2";
import { mySwalClass } from "../utils/alert";

export default {
  name: "BaseUserTable",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errors: [],
      selectedDegradations: [],
      heuresCloture: [
        "inactif",
        "10h",
        "11h",
        "12h",
        "13h",
        "14h",
        "15h",
        "16h",
        "17h",
        "18h",
        "19h",
        "20h",
        "21h",
        "22h"
      ],
      singleSelect: false,
      search: "",
      config: "",
      mdpDialog: false,
      confDialog: false,
      fromMail: undefined,
      tel: undefined,
      emailOK: undefined,
      bqo: undefined,
      rco: undefined,
      co: undefined,
      onClose: undefined,
      smsRci: undefined,
      prestaR2R: undefined,
      ifg: undefined,
      currentMdp: undefined,
      newMdp: undefined,
      confirmMdp: undefined,
      show1: false,
      show2: true,
      password: "Password",
      rules: [
        v => !!v || "obligatoire",
        v => (v && v.length >= 8) || "8 caractères minimum"
      ],
      selectedUsers: [],
      headers: [
        {
          text: "NOM",
          align: "start",
          sortable: true,
          value: "name",
          class: "primary white--text"
        },
        {
          text: "Agence(s)",
          align: "center",
          sortable: true,
          value: "defaultEnt",
          class: "primary white--text"
        },
        {
          text: "CREE LE",
          align: "start",
          sortable: true,
          value: "date_creation",
          class: "primary white--text"
        },
        {
          text: "DERNIER ACCES",
          align: "start",
          sortable: true,
          value: "last_login",
          class: "primary white--text"
        },
        {
          text: "UPDATE MDP",
          align: "start",
          sortable: true,
          value: "password_last_update",
          class: "primary white--text"
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          class: "primary white--text"
        },
        {
          text: "Administrateur",
          align: "center",
          sortable: true,
          value: "isAdmin",
          class: "primary white--text"
        },
        {
          text: "Notif. incidents",
          align: "center",
          sortable: true,
          value: "isNotif",
          class: "primary white--text"
        },
        {
          text: "Notif. EDL",
          align: "center",
          sortable: true,
          value: "isEdl",
          class: "primary white--text"
        },
        {
          text: "Accès plateforme",
          align: "center",
          sortable: true,
          value: "isPass",
          class: "primary white--text"
        }
      ],
      page: 1,
      pageCount: 0,
      listItems: []
    };
  },
  computed: {
    ...mapGetters([
      "getUserToken",
      "getUsers",
      "getUserId",
      "getEntity",
      "getUserProfiles",
      "getActiveEntity"
    ]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    },
    mailLink() {
      let urlCookie =
        PORTAL_CONFIG.SERVER_WEB +
        "/" +
        PORTAL_CONFIG.APP_NAME +
        "/" +
        PORTAL_CONFIG.CLIENT_NAME +
        "/setup_user.php?user=" +
        this.getUserToken;
      return `mailto:?subject=Votre cookie staff&body=${urlCookie}`;
    },
    autoCookieLink() {
      let urlCookie =
        PORTAL_CONFIG.SERVER_WEB +
        "/" +
        PORTAL_CONFIG.APP_NAME +
        "/" +
        PORTAL_CONFIG.CLIENT_NAME +
        "/setup_user.php?user=" +
        this.getUserToken +
        "&match";
      return urlCookie;
    },
    isKitAdmin() {
      return this.getUserProfiles.includes(adminKitProfile);
    },
    searched() {
      this.unselectAll();
      if (this.search.includes(" ")) {
        let nbMots = 0;
        const space = / /g;
        let searches = [];
        while (space.exec(this.search)) {
          nbMots++;
        }
        for (let i = 0; i < nbMots; i++) {
          searches[i] = this.search.split(" ")[i];
        }
        if (this.search.split(" ")[nbMots]) {
          nbMots++;
          searches[nbMots - 1] = this.search.split(" ")[nbMots - 1];
        }
        if (nbMots == 2) {
          return searches[0] && searches[1];
        }
        if (nbMots == 3) {
          return searches[0] && searches[1] && searches[2];
        }
      }
      return this.search;
    }
  },
  methods: {
    unselectAll() {
      this.selectedUsers = [];
    },
    loadConfData() {
      /*
      $client_OT='0'; // 0 ou 1 si sur Dégradation : "J'autorise l'OT à obtenir mon adresse mail"
      $client_AG='1'; // 0 ou 1  si sur Dégradation : "J'autorise l'agence à intervenir en mon abscence"
      $client_CI='1'; // 0 ou 1 : 1 = checkin obligatoire (check_checkin activé)
      $client_KIT='0'; //0 pas proposé, 1 proposé, 2 choix par defaut : 'Je souhaite resté informé'
      $client_RCO='1'; // 0 ou 1 : 1 mail de rappel check out activé //modifiable via portail
      $client_BQO='1'; // 0 ou 1 : 1 mail bilan quotidien activé //modifiable via portail
      $client_CO='1'; // 0 ou 1 : check out auto à 13h //modifiable via portail
      $client_on_close="R2R"; // R2R ou OUT : état du lgt après cloture depuis portail //modifiable via portail
      $client_presta='0'; // "presta":{"R2R":1}
      $client_SMS_RCI='0'; //0 ou 1 pour rappel par SMS du checkIn //visible via portail
      $client_SMS_RCO='0'; //0 ou 1 pour rappel par SMS du checkOut //visible via portail
      $client_ASK_GPS='1'; // 0 ou 1 : 0 si on ne pose pas la question, à la création du logement 
      $client_ASK_PHOTO_MAIN='1'; // 0 ou 1 : 0 si on ne pose pas la question, photo principale
      $clients_logo_ID='1'; // ID du logo de l'agence Catégorie LOGO
      $clients_logo_ID2='1'; // ID du logo de l'agence Catégorie LOGO2
      $clients_logo_RGB='185,45,50'; // Code couleur de l'agence
      $client_avis=NULL;
      $client_ifg='https://www.valthorensimmobilier.com';  //modifiable via portail
      $client_resa_mail_validator=''; // mail de validateur d'une resa
      */
      let conf = JSON.parse(
        this.getEntity.filter(el => el.id == this.$route.params.id)[0].website
      );
      this.config = conf;
      this.fromMail = conf.mail.from_name;
      this.tel = this.getEntity.filter(
        el => el.id == this.$route.params.id
      )[0].phonenumber;
      this.emailOK = this.getEntity.filter(
        el => el.id == this.$route.params.id
      )[0].email;
      try {
        this.bqo = conf.BQO.toString();
      } catch {
        this.bqo = "0";
      }
      try {
        this.rco = conf.RCO.toString();
      } catch {
        this.rco = "0";
      }
      try {
        if (conf.CO.toString() == 0) {
          this.co = "inactif";
        } else this.co = conf.CO.toString() + "h";
      } catch {
        this.co = "inactif";
      }
      this.onClose = conf.on_close;
      this.smsRci = conf.SMS_RCI;
      try {
        this.prestaR2R = conf.presta.R2R.toString();
      } catch {
        this.prestaR2R = "0";
      }
      this.ifg = conf.ifg;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateH(date) {
      return moment(date).format("YYYY-MM-DD - HH:mm");
    },
    closeMdp() {
      this.currentMdp = undefined;
      this.newMdp = undefined;
      this.confirmMdp = undefined;
      this.mdpDialog = false;
    },
    closeConfig() {
      this.confDialog = false;
    },
    resetConfig() {
      this.loadConfData();
    },
    async changeMdp(user) {
      let pass1Input = "";
      let pass2Input = "";

      const { value: formValues } = await Swal.fire({
        title: "Modification de mot de passe",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass,
        focusConfirm: false,
        html: `En raison de la politique de sécurité il est necessaire de modifier votre mot de passe :
          <input type="text" id="pass1" class="swal2-input" placeholder="Nouveau mot de passe">
          <input type="text" id="pass2" class="swal2-input" placeholder="Confirmer le nouveau mdp">
        `,
        didOpen: () => {
          const popup = Swal.getPopup();
          pass1Input = popup.querySelector("#pass1");
          pass2Input = popup.querySelector("#pass2");
          pass1Input.onkeyup = event =>
            event.key === "Enter" && Swal.clickConfirm();
          pass2Input.onkeyup = event =>
            event.key === "Enter" && Swal.clickConfirm();
        },
        preConfirm: () => {
          const pass1 = document.getElementById("pass1").value;
          const pass2 = document.getElementById("pass2").value;
          if (pass1.length < 6) {
            Swal.showValidationMessage(
              `Le mot de passe doit posséder plus de 6 caractères`
            );
          } else if (pass1 !== pass2) {
            Swal.showValidationMessage(
              `Les mots de passe ne correspondent pas`
            );
          }
          return { pass1, pass2 };
        }
      });
      try {
        await USER_API.mdpUser(
          user[0].id,
          formValues["pass1"],
          formValues["pass2"]
        );
      } catch ({ response }) {
        this.errors.push({
          title: "attention",
          msg: response.data,
          snackbar: true
        });
      }
      this.unselectAll();
      setTimeout(() => {
        this.errors = [];
        this.$router.go(0);
      }, 5000);
    },
    async changeConf() {
      let ent = this.$route.params.id;
      let saveCO = "";
      if (this.co == "inactif") {
        saveCO = 0;
      } else saveCO = this.co.split("h")[0];
      this.config.mail.from_name = this.fromMail;
      this.config.BQO = this.bqo;
      this.config.RCO = this.rco;
      this.config.CO = saveCO;
      this.config.on_close = this.onClose;
      //this.sms

      if (!this.config.presta || this.config.presta == 0) {
        this.config.presta = { R2R: this.prestaR2R };
      } else this.config.presta.R2R = this.prestaR2R;
      this.config.ifg = this.ifg;
      await USER_API.switchActiveEntity(ent);
      try {
        USER_API.changeConf(ent, this.tel, this.emailOK, this.config);
      } catch ({ response }) {
        this.errors.push({
          title: "attention",
          msg: response.data,
          snackbar: true
        });
      }
      setTimeout(() => {
        this.errors = [];
        this.$router.go(0);
      }, 5000);
      this.confDialog = false;
    },
    addEmail(element) {
      let listItem = element;
      element.forEach(async (object, index) => {
        await USER_API.getUserEmail(object.id)
          .then(({ data }) => {
            const i = index;
            this.$set(listItem, i, { ...listItem[i], email: data[0].email });
            //if (this.emailOK.includes(data[0].email))
            //this.$set(listItem, i, { ...listItem[i], isEdl: "1" });
          })
          .catch(() => {
            this.errors.push({
              title: "attention",
              msg: "email non defini",
              snackbar: true
            });
          });
      });
      return listItem;
    },
    addIsNotif(element) {
      let listItem = element;
      element.forEach(async (object, index) => {
        let notif = 0;
        let admin = 0;
        let edl = 0;
        if (object.password_last_update) object.isPass = 1;
        await USER_API.getUsersProfile(object.id, this.$route.params.id)
          .then(({ data }) => {
            data.forEach(element => {
              element.profiles_id == 24 ? notif++ : "";
              element.profiles_id == 49 ? admin++ : "";
              element.profiles_id == 50 ? edl++ : "";
            });
            const i = index;
            this.$set(listItem, i, { ...listItem[i], isNotif: notif });
            this.$set(listItem, i, { ...listItem[i], isAdmin: admin });
            this.$set(listItem, i, { ...listItem[i], isEdl: edl });
          })
          .catch(() => {
            this.errors.push({
              title: "attention",
              msg: "email non defini",
              snackbar: true
            });
          });
      });
      return listItem;
    },
    changeNotif(selectedUsers) {
      Swal.fire({
        title: "Êtes vous sûrs?",
        text:
          "Vous voulez inverser la notification des incidents pour " +
          selectedUsers.length +
          " utilisateur(s)",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.changeNotifConfirmed(selectedUsers);
        }
      });
    },
    async changeNotifConfirmed(selectedUsers) {
      for (const user of selectedUsers) {
        USER_API.getUsersProfileId(user.id, 24, this.$route.params.id)
          .then(response => {
            if (response.data.length > 0) {
              USER_API.delProfile(response.data[0].id).then(() => {
                user.isNotif = "";
              });
            } else {
              USER_API.addProfile(user.id, 24, this.$route.params.id).then(
                () => {
                  user.isNotif = "1";
                }
              );
            }
          })
          .finally(() => {
            this.unselectAll();
          });
      }
    },
    changeEdl(selectedUsers) {
      Swal.fire({
        title: "Êtes vous sûrs?",
        text:
          "Vous voulez inverser la notification des EDL pour " +
          selectedUsers.length +
          " utilisateur(s)",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.changeEdlConfirmed(selectedUsers);
        }
      });
    },
    async changeEdlConfirmed(selectedUsers) {
      for (const user of selectedUsers) {
        USER_API.getUsersProfileId(user.id, 50, this.$route.params.id)
          .then(response => {
            if (response.data.length > 0) {
              USER_API.delProfile(response.data[0].id).then(() => {
                user.isEdl = "";
              });
            } else {
              USER_API.addProfile(user.id, 50, this.$route.params.id).then(
                () => {
                  user.isEdl = "1";
                }
              );
            }
          })
          .finally(() => {
            this.unselectAll();
          });
      }
    },
    loadAgence(element) {
      /* pour la gestion des users de N5, il faut que le user soit par defaut sur l'entite de N5
      - > KIT > SUD_EST > ECHAPPEE_BLEUE > GASSIN / kit_logement (R) (PAR DEFAUT)
      - > KIT > SUD_EST > ECHAPPEE_BLEUE / kit_logement
      - > KIT > SUD_EST > ECHAPPEE_BLEUE > GASSIN / kit+gest (R)
      */
      let listItem = element;
      let listEntity = [];
      this.getEntity.forEach(element => {
        listEntity.push([element.id, element.name, element.level]);
      });
      element.forEach(async (object, index) => {
        const i = index;
        let name = "";
        listEntity.find(el => {
          el[0] == object.entities_id ? (name = el[1]) : "";
          if (el[2] == 4) name = "*";
        });
        this.$set(listItem, i, { ...listItem[i], defaultEnt: name });
      });
      return listItem;
    },
    async modUsers(selectedUsers) {
      //let entityN4 = this.getActiveEntity.entities_id;
      //await USER_API.setActiveEntity(entityN4);
      for (const user of selectedUsers) {
        let emailUser = "";
        USER_API.getUserEmail(user.id).then(response => {
          if (response.data[0]) emailUser = response.data[0].email;
          Swal.fire({
            title: "Modifier l'email",
            input: "email",
            inputValue: emailUser,
            showCancelButton: true,
            confirmButtonText: "Confirmer",
            customClass: mySwalClass
          }).then(result => {
            if (result.isConfirmed) {
              user.email = result.value;
              if (emailUser) {
                try {
                  USER_API.putUserEmail(response.data[0].id, result.value);
                } catch ({ response2 }) {
                  this.errors.push({
                    title: "Erreur ",
                    msg: response2.data,
                    snackbar: true
                  });
                }
              } else {
                try {
                  USER_API.postUserEmail(user.id, result.value);
                } catch ({ response3 }) {
                  this.errors.push({
                    title: "Erreur ",
                    msg: response3.data,
                    snackbar: true
                  });
                }
              }
              this.unselectAll();
              Swal.fire({
                title: "OK",
                showConfirmButton: false,
                customClass: mySwalClass,
                timerProgressBar: true,
                icon: "success",
                timer: 1500
              });
            }
          });
        });
      }
    },
    delUsers(selectedUsers) {
      if (selectedUsers[0].name.slice(-2) == "_1") {
        Swal.fire({
          title: "Attention",
          text: "Vous ne pouvez pas supprimer l'utilisateur principal",
          showConfirmButton: false,
          customClass: mySwalClass,
          timerProgressBar: true,
          icon: "warning",
          timer: 4000
        });
      } else {
        Swal.fire({
          title: "Êtes vous sûrs?",
          text:
            "Vous voulez supprimer " + selectedUsers.length + " utilisateur(s)",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirmer",
          customClass: mySwalClass
        }).then(result => {
          if (result.isConfirmed) {
            this.delUsersConfirmed(selectedUsers);
          } else this.unselectAll();
        });
      }
    },
    async delUsersConfirmed(selectedUsers) {
      //let entityN4 = this.getActiveEntity.entities_id;
      let active = 0;
      //await USER_API.setActiveEntity(entityN4);
      for (const user of selectedUsers) {
        USER_API.deactivateUser(user.id, active).then(
          () => (user.name = "supprimé")
        );
      }
      this.unselectAll();
      setTimeout(() => {
        this.errors = [];
        this.$router.go(0);
      }, 1000);
    }
  },
  mounted() {
    this.loadConfData();
    this.loadAgence(this.items);
    this.listItems = this.addEmail(this.items);
    this.listItems = this.addIsNotif(this.items);
    //this.listItems = this.addIsNotif(this.items);
  }
};
</script>
