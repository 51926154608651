<template>
  <v-container class="fill-height" fluid>
    <template>
      <v-row v-if="isCartoMap" class="mb-12" no-gutters>
        <v-col md="10" offset-md="1">
          <v-card width="100%">
            <map-view :list="locationListObjets"></map-view>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="isMobile">
      <v-card width="100%">
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="slide in documentsImages"
            :key="slide.id"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="10" offset-md="1">
          <v-card width="100%">
            <v-carousel
              cycle
              height="330"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="slide in documentsImages"
                :key="slide.id"
                :src="slide.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="isMobile">
      <v-row no-gutters class="mx-n4">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t("tableTitle.accommodation_list") }}
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row align="center" justify="center" class="mt-4" no-gutters>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t("tableTitle.accommodation_list") }}
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-card flat class="mt-4">
      <toggle-button
        class="mt-n1"
        :font-size="16"
        :value="sliderInit"
        :width="200"
        :height="35"
        :color="{
          checked: 'var(--v-primary-base)',
          unchecked: 'var(--v-primary-base)'
        }"
        :labels="{
          checked: 'Toutes les données',
          unchecked: 'chargement rapide'
        }"
        @change="dataRange()"
      />
      <v-tooltip open-delay="500" top color="#333F48">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="elevation-0 mt-n1" color="primary">
            mdi-information
          </v-icon>
        </template>
        <span class="pa-6"
          >Le chargement rapide n'affiche que les 1000 derniers éléments</span
        >
      </v-tooltip>
    </v-card>
    <v-card flat class="mt-4">
      <v-card-title>
        <label class="switch mr-4">
          <input type="checkbox" @click="toggleCheckbox" />
          <div class="slider round"></div>
        </label>
        Mode avancé</v-card-title
      >
    </v-card>
    <template v-if="this.checkbox">
      <v-card width="100%" class="pa-4 md-0 elevation-3" color="grey">
        <v-row>
          <base-user-table
            :items="staffList"
            :key="refreshKey"
            label="Liste du STAFF"
            :loading="loading"
          ></base-user-table>
        </v-row>
        <v-card v-if="isKitAdmin" class="mt-2">
          <v-dialog width="30%" v-model="confDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters class="pa-2">
                <v-col cols="1">
                  <v-btn
                    fab
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-currency-eur</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2" class="ml-n12 mr-12">
                  Configurer les services facturables
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-row>
                <service-editor :comment="note_service" title="Services">
                </service-editor>
              </v-row>
            </v-card>
          </v-dialog>
        </v-card>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapView from "../components/Map";
import AssetTableLight from "../clients/AssetTableLight";
import moment from "../plugins/moment";
import USER_API from "../api/user";
import BaseUserTable from "./BaseUserTable.vue";
import { PORTAL_CONFIG } from "../config";
import ServiceEditor from "../components/editor/ServiceEditor";
import { adminKitProfile } from "../utils";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "LgtsAssetsDetails",
  components: {
    MapView,
    AssetTableLight,
    BaseUserTable,
    ServiceEditor,
    ToggleButton
  },
  data() {
    return {
      advancedMode: false,
      badCode: 0,
      checkbox: false,
      slider: "",
      activeEntity: null,
      isCartoMap: false,
      locationListObjets: [],
      search: "",
      refreshKey: 0,
      confDialog: false,
      userList: [],
      list: [],
      loading: false,
      note_service: ""
    };
  },
  //mixins: [AssetsData],
  mounted() {
    this.loadUsers();
    this.loadData();
  },
  created() {
    this.note_service = "merci de patienter";
    this.loadServiceNote();
  },
  computed: {
    ...mapGetters([
      "getTreeData",
      "getDocumentsImages",
      "getUserProfiles",
      "getAssets",
      "getUserToken",
      "getUserId",
      "getEntity",
      "getUserData"
    ]),
    sliderInit() {
      if (localStorage.getItem("custom_range")) return false;
      else return true;
    },
    currentAsset() {
      const assetId = this.$route.params.id;
      const asset = this.getAssets.find(asset => {
        return +asset.id === +assetId;
      });

      return asset ? asset : null;
    },
    currentLots() {
      const lots = this.currentAsset.children;
      const keys = lots.map(el => el.id);
      return this.getAssets.filter(asset => {
        return keys.includes(asset.id);
      });
    },
    documentsImages() {
      const assetID = this.$route.params.assetID;
      if (assetID) {
        const asset = this.treeAssetData.find(el => el.id === +assetID);
        const entitiesId = asset.children.map(entity => entity.id);
        return this.getDocumentsImages.filter(doc => {
          return (
            doc.entities_id === assetID ||
            entitiesId.includes(doc.entities_id) ||
            doc.entities_id === 1
          );
        });
      }
      return this.getDocumentsImages;
    } /*
    images() {
      const assetId = this.$route.params.id;
      const assetImages = this.getDocumentsImages.filter(
        el => el.entities_id === +assetId
      );
      return assetImages;
    },
    locationList() {
      const size = this.currentLots.length;
      let isGps = 0;
      for (let i = 0; i < size; i++) {
        if (this.currentLots[i].latitude && this.currentLots[i].longitude) {
          isGps = 1;
        }
      }
      if (isGps === 1) {
        return this.currentLots
          .filter(el => el.level === 5)
          .map(el => {
            return {
              name: el.name,
              lat: el.latitude,
              lng: el.longitude,
              link: { name: "lots.show", params: { id: el.id } }
            };
          });
      } else {
        return [
          {
            name: this.currentAsset.name,
            lat: this.currentAsset.latitude,
            lng: this.currentAsset.longitude
          }
        ];
      }
    }, */,
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    staffList() {
      let myEntities = [];
      let myLevel = 0;
      if (this.getUserData.entities_id > 0)
        //si l'entite par defaut n'est pas definie
        myLevel = this.getEntity.find(
          el => el.id == this.getUserData.entities_id
        ).level;
      if (this.$route.params.id == this.getUserData.entities_id || myLevel < 4)
        myEntities = [parseInt(this.$route.params.id)];
      this.getEntity
        .filter(el => el.entities_id == this.$route.params.id)
        .forEach(i => myEntities.push(i.id));
      return this.userList.filter(
        el =>
          el.id > 0 &&
          myEntities.includes(el.entities_id) &&
          !el.name.includes("_00") &&
          //!el.password_last_update &&
          el.is_active == 1 &&
          !PORTAL_CONFIG.HIDDEN_USERID.includes(parseInt(el.id))
      );
    },
    gestList() {
      return this.userList.filter(el => el.id > 88 && el.password_last_update);
    },
    isKitAdmin() {
      return this.getUserProfiles.includes(adminKitProfile);
    }
  },
  methods: {
    moment,
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id == entity.entities_id);
      return parent ? parent.name : "";
    },
    loadUsers() {
      this.badCode++;
      let listEntity = [];
      this.getEntity.forEach(element => listEntity.push(element.id));
      USER_API.getUsers()
        .then(({ data }) => {
          this.userList = data.filter(el =>
            listEntity.includes(el.entities_id)
          );
        })
        .finally(() => (this.badCode > 1 ? this.refreshKey++ : ""));
    },
    loadServiceNote() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "ZZZZZ") {
                this.note_service = data[i].content.substring(5);
                break;
              } else {
                this.note_service =
                  "&lt;ul&gt;&lt;li&gt;&lt;/li&gt;&lt;/ul&gt;";
              }
            }
          } else {
            this.note_service = "&lt;ul&gt;&lt;li&gt;&lt;/li&gt;&lt;/ul&gt;";
          }
        });
      });
    },
    loadData() {
      if (!this.display) {
        this.loading = true;
        USER_API.getEquipment("Logement")
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            this.locationListObjets = [];
            //let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              /*pour mettre les points sur la carte
              for (let i = 0; i < size; i++) {
                if (
                  this.list[i].gps &&
                  this.list[i].gps !== "/" &&
                  this.list[i].gps !== "0/0"
                ) {
                  nbgps = nbgps + 1;
                  if (this.list[i].states_id > 0) {
                    this.locationListObjets.push({
                      name:
                        isNaN(this.list[i].name) === true
                          ? this.list[i].name.toUpperCase()
                          : this.list[i].name,
                      states_id: this.list[i].states_id,
                      /* si souci avec les markers, il faudrait peut etre utiliser cela
                      operationnel:
                        this.list[i].operationnel !== undefined
                          ? this.list[i].operationnel
                          : "NA",*/ /*
                      lat: this.list[i].gps.split("/")[0],
                      lng: this.list[i].gps.split("/")[1],
                      link:
                        PORTAL_CONFIG.SERVER_WEB +
                        "/" +
                        PORTAL_CONFIG.APP_NAME +
                        "/" +
                        PORTAL_CONFIG.CLIENT_NAME +
                        "/loader.php?script=ticket_it&user=" +
                        this.getUserToken +
                        "&code_pi=" +
                        this.list[i].code_pi +
                        "&lot=info_lgt&gest"
                    });
                  }
                }
              }*/
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                if (this.list[i].states_id === 1) {
                  this.list[i].states_id = "OK";
                } else {
                  this.list[i].states_id = "NOK";
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
                this.list[i].dimension = this.list[i].dimension;
                /*if (this.list[i].expirationdate != null) {
                  this.list[i].duree =
                    this.list[i].expirationdate.split("-")[0] -
                    moment().format("YYYY") +
                    " an(s)";
                }*/
              }
            }
            /*
            if (this.currentAsset.altitude == 0) {
              nbgps = 0;
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }*/
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    dataRange() {
      if (this.slider === "") {
        this.slider = this.sliderInit;
      }
      this.slider = !this.slider;
      if (this.slider === true) {
        localStorage.removeItem("custom_range");
      } else {
        localStorage.setItem("custom_range", "0-1000");
      }
    },
    toggleCheckbox() {
      USER_API.changeActiveEntity().then(() => {
        this.loadUsers();
        this.checkbox = !this.checkbox;
        this.$emit("setCheckboxVal", this.checkbox);
      });
    }
  },
  watch: {
    display() {
      this.loadUsers();
      this.loadServiceNote();
      this.loadData();
    },
    $route() {
      this.loadUsers();
      this.loadData();
    },
    active() {
      this.loadServiceNote();
    }
  }
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--v-primary-base);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--v-primary-base);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
