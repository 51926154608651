<template>
  <v-container>
    <template>
      <v-row class="mb-12" no-gutters>
        <v-col md="11">
          <v-card class="ml-12" outlined tile>
            <map-view :list="locationList"></map-view>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="isMobile">
      <v-row no-gutters class="mx-n4">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t("tableTitle.list_equipment") }}
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row align="center" justify="center" class="mt-4">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Liste des entités
            </v-card-title>
            <asset-table-light :items="currentLots"></asset-table-light>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapView from "../components/Map";
import AssetTableLight from "../clients/AssetTableLight";
import moment from "../plugins/moment";
import USER_API from "../api/user";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "InfoAssetsDetails",
  components: {
    MapView,
    AssetTableLight
  },
  data() {
    return {
      activeEntity: null,
      locationList: []
    };
  },
  //mixins: [AssetsData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getTreeData",
      "getDocumentsImages",
      "getUserProfiles",
      "getAssets",
      "getUserToken",
      "getUserId"
    ]),
    currentAsset() {
      const assetId = this.$route.params.id;
      const asset = this.getAssets.find(asset => {
        return +asset.id === +assetId;
      });

      return asset ? asset : null;
    },
    currentLots() {
      const lots = this.currentAsset.children;
      const keys = lots.map(el => el.id);
      return this.getAssets.filter(asset => {
        return keys.includes(asset.id);
      });
    },
    images() {
      const assetId = this.$route.params.id;
      const assetImages = this.getDocumentsImages.filter(
        el => el.entities_id === +assetId
      );
      return assetImages;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isInfo() {
      return this.getUserProfiles[0] === 22;
    }
  },
  methods: {
    moment,
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id == entity.entities_id);
      return parent ? parent.name : "";
    },
    loadData() {
      if (!this.display) {
        this.loading = true;
        USER_API.getLocations()
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            this.locationList = [];
            let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              //CCA pas parfait car la carte ne se met pas à jour quand on change de lot
              for (let i = 0; i < size; i++) {
                if (this.list[i].latitude && this.list[i].latitude !== "0") {
                  nbgps = nbgps + 1;
                  this.locationList.push({
                    name:
                      isNaN(this.list[i].name) === true
                        ? this.list[i].name.toUpperCase()
                        : this.list[i].name,
                    operationnel: this.list[i].operationnel,
                    /* si souci avec les markers, il faudrait peut etre utiliser cela
                    operationnel:
                      this.list[i].operationnel !== undefined
                        ? this.list[i].operationnel
                        : "NA",*/
                    lat: this.list[i].latitude,
                    lng: this.list[i].longitude,
                    link:
                      PORTAL_CONFIG.SERVER_WEB +
                      "/" +
                      PORTAL_CONFIG.APP_NAME +
                      "/" +
                      PORTAL_CONFIG.CLIENT_NAME +
                      "/itemtype.php?user=" +
                      this.getUserToken +
                      "&ept=" +
                      this.list[i].room +
                      "&entity=" +
                      this.$route.params.id
                  });
                } else {
                  this.isCartoMap = false;
                }
              }
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
