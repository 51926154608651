<template>
  <v-card width="100%">
    <v-card-text v-if="isMobile">
      <div class="editor">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <div class="menubar">
            <v-btn
              class="menubar__button mr-2 mb-2"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
              fab
              x-small
              title="Gras"
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
              fab
              x-small
              title="Titre"
            >
              T1
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
              fab
              x-small
              title="Sous-titre"
            >
              T2
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
              fab
              x-small
              title="Liste"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              @click="commands.horizontal_rule"
              fab
              x-small
              title="Ligne horizontale"
            >
              <v-icon> mdi-minus</v-icon>
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              @click="commands.undo"
              fab
              x-small
              title="Annuler"
            >
              <v-icon>mdi-undo-variant</v-icon>
            </v-btn>

            <v-btn
              class="menubar__button mr-2 mb-2"
              @click="commands.redo"
              fab
              x-small
              title="Refaire"
            >
              <v-icon>mdi-redo-variant</v-icon>
            </v-btn>
          </div>
        </editor-menu-bar>
        <br />
        <editor-content class="editor__content" :editor="editor" />
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div class="editor">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <v-card class="pa-3">
            <v-row>
              <v-col cols="3" class="menubar headline display-1 mt-1">
                {{ title }}
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="menubar__button ml-4 mt-1"
                  :class="{ 'is-active': isActive.bullet_list() }"
                  @click="commands.bullet_list"
                  fab
                  x-small
                  color="primary"
                  title="Liste"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" align="right">
                ex de saisie :
              </v-col>
              <v-col cols="4">- Service 1 €50<br />- Service 2 €50</v-col>
            </v-row>
          </v-card>
        </editor-menu-bar>
        <br />
        <editor-content class="elevation-3 editor__content" :editor="editor" />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn class="mr-12" color="primary" large @click="updateComment">{{
        $t("editor.save")
      }}</v-btn>
      <i>cliquer en dehors pour fermer ou annuler</i>
    </v-card-actions>
    <v-overlay :value="updating" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { decode } from "../../utils";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import USER_API from "../../api/user";
import { mapGetters } from "vuex";

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";
export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    comment: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Commentaire"
    }
  },
  data() {
    return {
      editor: null,
      plaque0: null,
      updating: false,
      content: ""
    };
  },
  mounted() {
    this.content = this.comment;
    //this.loadData();
    this.initEditor();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getUserId",
      "getSessionToken"
    ]),
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    updateComment() {
      this.updating = true;
      const entityId = this.$route.params.id;
      USER_API.addNote(entityId, this.content).then(() => {
        //this.$store.commit("updateEntityComment", data);
        this.updating = false;
      });
    },
    loadData() {
      USER_API.getEquipment("Logement").then(response => {
        if (response.data.filter(el => el.code_pi != "-")) {
          if (response.data.filter(el => el.code_pi != "-")[0]) {
            this.plaque0 = response.data.filter(el => el.code_pi != "-")[0].id;
          }
        }
      });
    },
    initEditor() {
      if (this.editor) {
        this.editor.destroy();
      }

      this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: decode(this.content)
      });
      this.editor.on("update", ({ getHTML }) => {
        // get new content on update
        const newContent3 = "ZZZZZ" + getHTML();
        const newContent2 = newContent3
          .split("<p>")
          .join("")
          .split("</p>")
          .join("");
        const newContent = newContent2
          .split("<a href")
          .join('<a target="_blank" href');
        this.content = newContent;
      });
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    comment() {
      this.content = this.comment;
      this.$nextTick(() => {
        this.editor.setContent(decode(this.content));
      });
    },
    $route() {
      this.initEditor();
    }
  }
};
</script>
